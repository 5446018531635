<template>
    <div>
        <div class="row" id="flow-editor-page-container" v-if="canLoadPage">
            <div class="col">
                <div class="card mb-2 editor-edit-name">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-auto">
                                <a @click="back" class="btn" id="backButton">
                                    &larr; Voltar
                                </a>
                            </div>
                            <div class="col">
                                <div class="">
                                    <input type="text" class="form-control" placeholder="Nome do flow" name="flow-name"
                                        v-model="flow.name" id="flowNameInput" />
                                </div>
                            </div>
                            <div class="col-auto">
                                <label class="form-check form-switch spacer-top">
                                    <input class="form-check-input" type="checkbox" v-model="flow.enabled"
                                        id="flowEnabledCheckbox" />
                                    <span class="form-check-label">Ativo</span>
                                </label>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-primary" @click="saveFlow" id="saveFlowButton">
                                    <i class="ti ti-device-floppy"></i>
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-3 editor-toolbar-top">
                            <div class="col">
                                <div class="row text-right">
                                    <div class="col">
                                        <div class="btn-group">
                                            <button class="btn" @click="showTemplateGallery"
                                                id="showTemplatesGalleryButton">
                                                <i class="ti ti-template"></i>
                                                Templates
                                            </button>
                                            <button class="btn" @click="selectImportPosition()"
                                                id="selectImportPositionButton">
                                                <i class="ti ti-file-import"></i>
                                                Importar
                                            </button>
                                            <button class="btn" @click="exportFlow" id="exportFlowButton">
                                                <i class="ti ti-file-export"></i>
                                                Exportar
                                            </button>
                                            <button class="btn position-relative" @click="openVariables()" v-if="flow"
                                                id="flowVariablesButton">
                                                <i class="ti ti-code"></i>
                                                Variáveis
                                                <span v-if="flow.variables &&
                                    flow.variables.length
                                    " class="badge badge-notification badge-pill">{{
                                    flow.variables.length
                                }}</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-auto">
                                        <button @click="toggleDebugger()" class="btn bg-blue-lt"
                                            :disabled="this.id == 'new'" id="toggleDebuggerButton">
                                            <i class="ti ti-radar-2"></i>
                                            Debugger
                                        </button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn bg-red-lt" :disabled="!canExecute ||
                                    !showDebugger ||
                                    debugMode
                                    " @click="
                                    callFlowExecutionWithDebugger()
                                    " data-toggle="popover"
                                            data-content="O fluxo deve conter um conector 'Manual Trigger' para execução manual"
                                            id="executeDebugButton">
                                            <i class="ti ti-bug"></i>
                                            Executar com Debug
                                        </button>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn bg-red-lt" :disabled="!canExecute"
                                            @click="callFlowExecution()" data-toggle="popover"
                                            data-content="O fluxo deve conter um conector 'Manual Trigger' para execução manual"
                                            id="executeButton">
                                            <i class="ti ti-bolt"></i>
                                            Executar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <input type="hidden" v-model="flow.data" id="flowDataButton" />
                                    <iframe src="/flow-editor/index.html" frameborder="0" id="flow-editor-iframe"
                                        @load="iframeLoaded"></iframe>

                                    <div class="row editor-status-bar-bottom">
                                        <div class="col">
                                            <span class="text-orange">Action/Trigger:
                                                {{ nodesCount }}</span>
                                            &nbsp;
                                            <span class="text-blue">Controle de fluxo:
                                                {{
                                    nodesCountFlowcontrol
                                }}</span>
                                            &nbsp;
                                            <span class="text-azure">Total de conectores:
                                                {{
                                        nodesCount +
                                        nodesCountFlowcontrol
                                    }}</span>
                                        </div>
                                        <div class="col-auto">
                                            <small v-show="flowUpdated" class="text-mutted">Existem alterações não
                                                salvas
                                                no fluxo</small>
                                            <small v-show="!flowUpdated" class="text-mutted"></small>
                                        </div>
                                        <div class="col-auto">
                                            <button @click="showFlowErrors" class="btn btn-sm bg-red-lt"
                                                v-if="flowErrors.length" id="showFlowErrorsButton">
                                                <i class="ti ti-alert-triangle"></i>
                                                Erros encontrados no fluxo
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FlowErrorsViewer :errors="flowErrors" :key="flowErrorsKey" v-if="flowErrorsKey" />
        <FlowDebugger v-if="showDebugger" :flow="flow" :key="debugggerComponentKey" @startDebugger="onDebugStart"
            @stopDebugger="onDebugStop" @debugModeMessage="onDebugModeMessage" />
        <FlowTemplateGallery @selected-flow="selectImportPosition" :key="templateGalleryComponentKey"
            v-if="templateGalleryComponentKey" />
        <FlowManualTriggerForm :flow="flow" :description="manualTriggerFormDescription"
            :fields="manualTriggerFormFields" :key="showManualTriggerForm" v-if="showManualTriggerForm" />
        <CodeEditorModal :content="codeEditorContent" :language="codeEditorLanguage" :key="showCodeEditorModalKey"
            v-if="showCodeEditorModal" @changeContent="updateCodeEditorContent" />
        <FlowVariablesModal :initVariables="flow.variables" :key="showVariablesModalKey" v-if="showVariablesModal"
            @update="updateVariables" />

        <input id="importFlowInput" ref="importFlowInput" type="file" accept=".json" style="display: none" />
    </div>
</template>

<script>
import { store } from "../store"
import EventBus from "../services/event-bus"
import Api from "../services/api"
import EngineApi from "../services/engine-api"
import moment from "moment"
import FlowErrorsViewer from "../components/FlowErrorsViewer.vue"
import FlowDebugger from "../components/FlowDebugger.vue"
import FlowTemplateGallery from "../components/FlowTemplateGallery.vue"
import FlowManualTriggerForm from "../components/FlowManualTriggerForm.vue"
import CodeEditorModal from "../components/CodeEditorModal.vue"
import FlowVariablesModal from "../components/FlowVariablesModal.vue"

export default {
    name: "FlowEditPage",
    components: {
        FlowErrorsViewer,
        FlowDebugger,
        FlowTemplateGallery,
        FlowManualTriggerForm,
        CodeEditorModal,
        FlowVariablesModal
    },
    data() {
        return {
            id: null,
            initialized: false,
            flowUpdated: false,
            flow: {},
            nodesCount: 0,
            nodesCountFlowcontrol: 0,
            flowErrors: [],
            iframeResponseReceived: false,
            iframeImageResponseReceived: false,
            iframeComponent: null,
            editorLoaded: false,
            serviceAccounts: [],
            flowErrorsKey: null,
            showDebugger: false,
            debugggerComponentKey: null,
            workspace_id: null,
            templateGalleryComponentKey: null,
            manualTriggerFormFields: [],
            manualTriggerFormDescription: null,
            showManualTriggerForm: false,
            flowTriggers: [],
            codeEditorName: "",
            codeEditorContent: "",
            codeEditorLanguage: "plaintext",
            showCodeEditorModal: false,
            showCodeEditorModalKey: null,
            showVariablesModal: false,
            showVariablesModalKey: null,
            isInFullscreen: false,
            debugMode: false,
            flowToImport: null
        }
    },
    computed: {
        canLoadPage() {
            if (this.id == "new") return true
            return this.id !== 'new' && this.flow._id
        },
        canExecute() {
            var can_execute = false

            if (!this.flow.enabled) return false
            if (!this.flowTriggers.length) return false

            this.flowTriggers.forEach((nodeTrigger) => {
                if (nodeTrigger.type == "core_manual_trigger") {
                    can_execute = true
                }
            })

            return can_execute
        }
    },
    async mounted() {
        Api.axiosInstance._disable_loaders = true
        EngineApi.axiosInstance._disable_loaders = true

        EventBus.emit("ajax-request-start")

        this.id = this.$route.params.id
        if (!this.id) {
            this.id = "new"
        }

        store.showSidebar = true
        store.sidebarSize = "mini"
        store.showHeader = false

        if (this.$route.query.fromNew) {
            store.backUrl = "/flows"
        }

        this.workspace_id = store.workspace._id

        if (this.id == "new") {
            // is new
            this.flow = {
                name: "",
                enabled: true,
                triggers: [],
                variables: [],
                data: null,
                workspace: this.workspace_id
            }

            this.showDebugger = false
            localStorage.setItem("live-debugger", 0)
            document.body.classList.remove("live-debugger-active")
        } else {
            var responseData = await Api.flows.get(this.id)
            this.flow = responseData.data

            // existing flows without variables
            if (this.flow.variables == undefined) {
                this.flow.variables = []
            }
        }

        document.body.classList.add("flow-editor")

        // register iframe component
        this.iframeComponent = document.getElementById("flow-editor-iframe")

        // show live debugger if stored in local storage
        if (parseInt(localStorage.getItem("live-debugger"))) {
            this.toggleDebugger()
        }

        // on before unload
        window.addEventListener("beforeunload", async (e) => {
            if (this.flowUpdated) {
                e.preventDefault()
                e.returnValue = ""
            }
        })

        // execute flow from event bus
        EventBus.on("execute-flow", (flow_id) => {
            if (flow_id == this.flow._id) {
                this.callFlowExecution()
            }
        })
        await this.switchTitle(this?.flow?.name)
    },
    methods: {
        // put name of flow in page title
        async switchTitle(FlowName) {
            const title_el = document.querySelector("title")

            if (title_el) title_el.innerHTML = FlowName
        },

        async iframeLoaded() {
            this.iframeComponent = document.getElementById("flow-editor-iframe")

            var nodesLibraryResponse = await EngineApi.getNodesLibrary()
            var nodesLibrary = nodesLibraryResponse.data

            var nodesLimit = parseInt(store.user.customer.config.node_limit)

            this.iframeComponent.contentWindow.initializeEditor(
                nodesLibrary.nodes,
                JSON.parse(this.flow.data),
                nodesLimit
            )

            // register external functions to call APIs
            this.iframeComponent.contentWindow.editor.registerExternalFunction(
                "service-accounts-list",
                Api.serviceAccounts.listOptions
            )
            this.iframeComponent.contentWindow.editor.registerExternalFunction(
                "slack-channels",
                Api.serviceAccounts.slack.getChannels
            )
            this.iframeComponent.contentWindow.editor.registerExternalFunction(
                "trello-boards",
                Api.serviceAccounts.trello.getBoards
            )
            this.iframeComponent.contentWindow.editor.registerExternalFunction(
                "trello-board-lists",
                Api.serviceAccounts.trello.getBoardLists
            )
            this.iframeComponent.contentWindow.editor.registerExternalFunction(
                "trello-board-custom-fields",
                Api.serviceAccounts.trello.getCustomFields
            )
            this.iframeComponent.contentWindow.editor.registerExternalFunction(
                "database-table-list",
                async () => {
                    let response = await Api.database.tables.listAll()
                    return { data: response.data.items }
                }
            )
            this.iframeComponent.contentWindow.editor.registerExternalFunction(
                "queue-list",
                async () => {
                    let response = await Api.queues.listAll()
                    return { data: response.data.items }
                }
            )
            this.iframeComponent.contentWindow.editor.registerExternalFunction(
                "form-list",
                async () => {
                    let response = await Api.forms.listAll()
                    return { data: response.data.items }
                }
            )

            this.iframeComponent.contentWindow.editor.addEventListener(
                "flow-initialized",
                async () => {
                    this.initialized = true
                    this.nodesCount =
                        this.iframeComponent.contentWindow.editor.getNodesCount(
                            true
                        )
                    this.nodesCountFlowcontrol =
                        this.iframeComponent.contentWindow.editor.getNodesCount() -
                        this.nodesCount
                    this.flowErrors =
                        this.iframeComponent.contentWindow.editor.getErrors()
                    this.flowTriggers =
                        this.iframeComponent.contentWindow.editor.getTriggers()

                    await this.resetDebugMode()

                    this.editorLoaded = true

                    if (this.showDebugger) {
                        this.setEditorDebuggerOpen()
                    } else {
                        this.setEditorDebuggerClosed()
                    }

                    window.getDebuggerBreakpoints = this.iframeComponent.contentWindow.editor.getDebuggerBreakpoints.bind(this.iframeComponent.contentWindow.editor)
                }
            )
            this.iframeComponent.contentWindow.editor.addEventListener(
                "flow-updated",
                () => {
                    if (this.initialized) {
                        this.flowUpdated = true
                    }
                    this.nodesCount =
                        this.iframeComponent.contentWindow.editor.getNodesCount(
                            true
                        )
                    this.nodesCountFlowcontrol =
                        this.iframeComponent.contentWindow.editor.getNodesCount() -
                        this.nodesCount
                    this.flowErrors =
                        this.iframeComponent.contentWindow.editor.getErrors()
                    this.flowTriggers =
                        this.iframeComponent.contentWindow.editor.getTriggers()
                }
            )
            this.iframeComponent.contentWindow.editor.addEventListener(
                "detached-code-editor",
                (data) => {
                    this.openCodeEditorModal(data)
                }
            )

            // show debug mode messages
            this.iframeComponent.contentWindow.editor.addEventListener(
                "view-debug-message",
                async (data) => {
                    EventBus.emit("view-debug-message", {
                        flow_id: this.flow._id,
                        data: data
                    })
                }
            )

            // get first errors
            this.flowErrors =
                this.iframeComponent.contentWindow.editor.getErrors()

            EventBus.emit("ajax-request-end")

            Api.axiosInstance._disable_loaders = false
            EngineApi.axiosInstance._disable_loaders = false
        },

        getFlowImage() {
            return this.iframeComponent.contentWindow.editor.exportToImage()
        },

        saveFlow: async function (e) {
            Api.axiosInstance._disable_loaders = true

            EventBus.emit("ajax-request-start")
            var imageData = await this.getFlowImage()
            var dataJson =
                this.iframeComponent.contentWindow.editor.exportToJson()

            // save flow
            var promise
            if (this.id == "new") {
                this.flow.data = dataJson
                this.flow.thumbnailData = imageData
                promise = Api.flows.create(this.flow)
            } else {
                // send only fields to update
                var flowData = {}
                flowData.name = this.flow.name
                flowData.thumbnailData = imageData
                flowData.data = dataJson
                flowData.enabled = this.flow.enabled
                flowData.variables = this.flow.variables

                promise = Api.flows.update(this.$route.params.id, flowData)
            }

            promise
                .then((response) => {
                    EventBus.emit("ajax-request-end")
                    Api.axiosInstance._disable_loaders = false

                    EventBus.emit("message", {
                        type: "success",
                        message: "Fluxo salvo com sucesso"
                    })

                    this.flowUpdated = false

                    if (this.id == "new") {
                        this.$router.push(
                            "/flows/" + response.data._id + "?fromNew=true"
                        )
                    }
                })
                .catch((error) => {
                    EventBus.emit("ajax-request-end")
                    Api.axiosInstance._disable_loaders = false

                    var error_message = error.response.data.error ?? error
                    EventBus.emit("message", {
                        type: "danger",
                        message:
                            "Ocorreu um erro ao salvar o fluxo: " +
                            error_message
                    })

                    this.flowUpdated = false
                })

            e.preventDefault()
        },

        showFlowErrors() {
            this.flowErrorsKey = Math.random()
        },

        // execution in edit
        async callFlowExecution() {
            this.manualTriggerFormFields = []
            this.manualTriggerFormDescription = null
            this.showManualTriggerForm = false

            try {
                var response = await EngineApi.flows.execute(this.flow._id)

                if (!response.data.success && response.data.form_fields) {
                    this.manualTriggerFormFields = response.data.form_fields
                    this.manualTriggerFormDescription =
                        response.data.form_description
                    this.showManualTriggerForm = Math.random()

                    return
                }

                if (!this.showDebugger) {
                    EventBus.emit("message", {
                        type: "success",
                        message:
                            "Flow iniciado! Verifique os logs de monitoramento para detalhes da execução."
                    })
                }
            } catch (e) {
                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao tentar executar o Flow!"
                })
            }
        },
        async callFlowExecutionWithDebugger() {
            EventBus.emit("call-start-debugger", {
                flow_id: this.flow._id,
                callback: "execute-flow"
            })
        },
        goToMonitoring() {
            var route = this.$router.resolve({
                path: "/monitoring",
                query: {
                    filters: JSON.stringify({
                        flow: this.flow._id,
                        date_alias: "1d"
                    })
                }
            })
            window.open(route.href)
        },
        async toggleDebugger() {
            this.debugggerComponentKey = Math.random()

            if (this.showDebugger) {
                this.showDebugger = false
                localStorage.setItem("live-debugger", 0)
                document.body.classList.remove("live-debugger-active")

                await this.resetDebugMode()
            } else {
                document.body.classList.add("live-debugger-active")
                this.showDebugger = true
                localStorage.setItem("live-debugger", 1)
            }

            if (this.editorLoaded) {
                if (this.showDebugger) this.setEditorDebuggerOpen()
                if (!this.showDebugger) this.setEditorDebuggerClosed()
            }
        },
        async resetDebugMode() {
            // stop debug mode (if is running)
            await EngineApi.flows.stopDebugMode(this.flow._id)
            this.iframeComponent.contentWindow.editor.resetDebugMode()
        },
        slugfy(string) {
            return string
                .toLowerCase()
                .replace(/[^\w ]+/g, "")
                .replace(/ +/g, "-")
        },
        onDebugStart() {
            this.debugMode = true
            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "debug-mode-start"
            )
        },
        onDebugStop() {
            this.debugMode = false
            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "debug-mode-stop"
            )
        },
        onDebugModeMessage(data) {
            this.iframeComponent.contentWindow.editor.appendDebugMessage(data)
        },
        exportFlow() {
            var flowData = JSON.parse(
                this.iframeComponent.contentWindow.editor.exportToJson()
            )
            flowData.data.exported_at = moment().format("YYYY-MM-DD HH:mm:ss")
            flowData.variables = this.flow.variables

            var datetime = moment().format("YYYYMMDDHHmmss")

            var dataStr =
                "data:text/json;charset=utf-8," +
                encodeURIComponent(JSON.stringify(flowData))

            var fileName =
                this.slugfy(this.flow.name || "untitled") +
                "-" +
                datetime +
                ".floui.json"
            var downloadAnchorNode = document.createElement("a")
            downloadAnchorNode.setAttribute("href", dataStr)
            downloadAnchorNode.setAttribute("download", fileName)
            document.body.appendChild(downloadAnchorNode) // required for firefox

            downloadAnchorNode.click()
            downloadAnchorNode.remove()
        },
        selectImportPosition(data = null) {
            EventBus.emit("message", {
                type: "info",
                message: "Selecione a posição no editor para importar o fluxo"
            })

            this.iframeComponent.contentWindow.editor.selectImportPosition(
                (position) => {
                    this.importFlow(position.x, position.y, data)
                }
            )
        },
        importFlow(positionX, positionY, data = null) {
            // data from argument
            if (data) {
                var flowData = JSON.parse(data)
                return this.iframeComponent.contentWindow.editor.importFlow(
                    flowData,
                    positionX,
                    positionY
                )
            }

            const importFlowInput = this.$refs.importFlowInput
            const file = importFlowInput.files[0]

            const handleImportFlow = (file) => {
                var reader = new FileReader()

                reader.onload = (e) => {
                    var flowData = JSON.parse(e.target.result)
                    EngineApi.flows
                        .validateFlowData(store.workspace, flowData)
                        .then((response) => {
                            if (response.data.isValid) {
                                this.iframeComponent.contentWindow.editor.importFlow(
                                    flowData,
                                    positionX,
                                    positionY
                                )
                                // import flow variables (append)
                                if (flowData.variables) {
                                    flowData.variables.forEach((variable) => {
                                        this.flow.variables.push(variable)
                                    })
                                }
                                EventBus.emit("message", {
                                    type: "success",
                                    message: "Arquivo importado com sucesso"
                                })
                            } else {
                                EventBus.emit("message", {
                                    type: "danger",
                                    message:
                                        "O arquivo possui erros enão pode ser importado"
                                })
                            }
                        })
                        .catch((error) => {
                            EventBus.emit("message", {
                                type: "danger",
                                message:
                                    "Ocorreu um erro ao importar o fluxo: " +
                                    error
                            })
                        })
                }

                reader.readAsText(file)
                importFlowInput.value = null
            }

            if (file) {
                handleImportFlow(file)
            } else {
                importFlowInput.addEventListener("change", (e) =>
                    handleImportFlow(e.target.files[0])
                )
                importFlowInput.click()
            }
        },

        showTemplateGallery() {
            this.templateGalleryComponentKey = Math.random()
        },

        openCodeEditorModal(data) {
            this.codeEditorContent = data.content
            this.codeEditorLanguage = data.language
            this.codeEditorName = data.name
            this.showCodeEditorModal = true
            this.showCodeEditorModalKey = Math.random()
        },
        updateCodeEditorContent(content) {
            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "update-code-editor-content",
                {
                    content: content,
                    name: this.codeEditorName
                }
            )
        },

        setEditorDebuggerOpen() {
            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "set-debugger-open"
            )
        },

        setEditorDebuggerClosed() {
            this.iframeComponent.contentWindow.editor.dispatchEvent(
                "set-debugger-closed"
            )
        },

        openVariables() {
            this.showVariablesModal = true
            this.showVariablesModalKey = Math.random()
        },
        updateVariables(data) {
            this.flow.variables = data
        },
        back() {
            if (store.backUrl) {
                var tmpValue = store.backUrl
                store.backUrl = null
                this.$router.push(tmpValue)
            } else {
                history.back()
            }
        }
    }
}
</script>
